import { compressAccurately } from 'image-conversion'
import FileType from 'file-type/browser'
import gifsicle from 'gifsicle-wasm-browser'
import getImageFileInfo from "@/utils/getImageFileInfo";
import {PngImage, SvgImage, AvifImage} from "@/core/compressPng";
// import * as Magick from './magickApi'
import { call } from 'wasm-imagemagick'

/**
 * @description 根据
 * @param blob
 * @param quality
 * @returns {Promise<*|Blob>}
 */
export async function compressImage(blob, { quality }) {
  /**
   * 当传递的清晰度小于 0.01 或者
   */
  if (quality >= 1) {
    return blob
  }
  if (quality < 0.01) {
    quality = 0.01
  }

  let outputBlob = await compressAccurately(blob, {
    accuracy: 0.95,
    size: (blob.size / 1024) * quality
  })
  /**
   * 如果转换后的格式发生了变化，则重新创建一个blob将原type传入
   */
  if (outputBlob.type !== blob.type) {
    return new Blob([outputBlob], { type: blob.type })
  }
  return outputBlob
}

/**
 * 压缩PNG
 * @param params
 * @returns {Promise<*>}
 */
export async function compressPngSpeed(params) {
  if (!(params instanceof Blob) && typeof params !== 'string') {
    throw Error('不是一个文件或者文件路径')
  }
  let src = params

  if (params instanceof Blob) {
    src = URL.createObjectURL(params)
  }

  let fetchedSourceImage = await fetch(src)
  let arrayBuffer = await fetchedSourceImage.arrayBuffer()
  let sourceBytes = new Uint8Array(arrayBuffer)
  const files = [{ name: 'srcFile.png', content: sourceBytes }]
  const command = [
    'convert',
    'srcFile.png',
    '-define',
    'png:compression-level=9',
    '-strip',
    '-depth',
    '8',
    '-colors',
    '255',
    'out.png'
  ]
  let result = await call(files, command)
  if (result.exitCode !== 0) {
    return
  }
  const outputImage = result.outputFiles[0]

  return outputImage.blob
}

export async function compressPng(blob, {colors, dithering}) {
  let {width, height} = await getImageFileInfo(blob)
  let options = {
    blob: blob,
    name: blob.name,
    width: width,
    height: height
  }
  let ins = new PngImage(options, {colors: colors || 128, dithering: dithering || 0.5})
  let compressInfo = await ins.compress()
  return compressInfo.blob
}

/**
 * @description 自定义压缩大小，github地址：https://github.com/WangYuLue/image-conversion
 * @param {Blob} blob - blob文件
 * @param {Object} config - 配置
 * @param {number} config.maxSize - 指定压缩尺寸，单位：kb
 * @returns {Promise<Blob>}
 */
export async function outputTheSpecifiedSize(blob, { maxSize }) {
  if (maxSize * 1024 > blob.size) {
    // return await increasePictureMemory(blob, maxSize * 1024)
    return blob
  }
  let outputBlob = await compressAccurately(blob, {
    size: maxSize // 单位：KB
  })
  /**
   * 如果转换后的格式发生了变化，则重新创建一个blob将原type传入
   */
  if (outputBlob.type !== blob.type) {
    return new Blob([outputBlob], { type: blob.type })
  }
  return outputBlob
}
function changeBufferSize(buffer, size) {
  const dataArray = new Uint8Array(buffer)
  let newDataArray = new Uint8Array(dataArray.length + size)
  newDataArray.set(dataArray, 0)
  for (let i = dataArray.length; i < newDataArray.length; i++) {
    newDataArray[i] = 0
  }

  return newDataArray
}
export async function increasePictureMemory(file, size) {
  if (!(file instanceof Blob)) {
    throw Error('参数不是Blob类型')
  }
  if (file.size >= size) {
    return file
  }
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = async function (event) {
      let arrayBuffer = event.target.result

      let newDataArrayBuffer = changeBufferSize(arrayBuffer, size - file.size)

      let { mime } = await FileType.fromBlob(file)
      let blob = new Blob([newDataArrayBuffer.buffer], { type: mime })
      resolve(blob)
    }
    reader.onerror = function (e) {
      reject(e)
    }

    reader.readAsArrayBuffer(file)
  })
}

/**
 * 压缩gif
 * @param file
 * @param options
 * @returns {Promise<unknown>}
 */
export const compressGif = async (file, options) => {
  let level = options.level || 70
  level = level > 200 ? 200 : level < 0 ? 0 : level
  let mode = options.mode || 'O1'
  if (!['O1', 'O2', 'O3'].includes(mode)) {
    throw new Error('mode参数只允许设置为O1、O2、O3')
  }
  return new Promise((resolve, reject) => {
    gifsicle
      .run({
        input: [
          {
            file: file,
            name: 'qing_miao.gif'
          }
        ],
        command: [`-${mode} --lossy=${level} qing_miao.gif -o /out/${file.name.replace(/\s/g, '_')}`]
      })
      .then(d => {
        resolve(d[0])
      })
      .catch(e => {
        reject(e)
      })
  })
}

/**
 * 压缩svg
 * @param blob
 * @returns {Promise<module:buffer.Blob>}
 */
export const compressSvg = async (blob) => {
  let {width, height} = await getImageFileInfo(blob)
  let options = {
    blob: blob,
    name: blob.name,
    width: width,
    height: height
  }
  let ins = new SvgImage(options)
  let compressInfo = await ins.compress()
  return compressInfo.blob
}

export const compressAvif = async (blob, params) => {
  let {width, height} = await getImageFileInfo(blob)
  let options = {
    blob: blob,
    name: blob.name,
    width: width,
    height: height
  }
  let ins = new AvifImage(options, {quality: params?.avifQuality, speed: params?.avifSpeed})
  let compressInfo = await ins.compress()
  return compressInfo.blob
}

/**
 * @description 根据
 * @param blob
 * @param quality
 * @returns {Promise<*|Blob>}
 */
export async function heicToJpg(blob) {
return new Promise(resolve => {
  const reader = new FileReader()
  reader.onload = async () => {
    const arrayBuffer = reader.result
    let sourceBytes = new Uint8Array(arrayBuffer)
    const files = [{ name: 'input.heic', content: sourceBytes }]
    const command = [
      'convert',
      'input.heic',
      'out.jpg'
    ]
    let result = await call(files, command)
    if (result.exitCode !== 0) {
      return
    }
    const outputImage = result.outputFiles[0]

    resolve(outputImage.blob)
  }
  reader.readAsArrayBuffer(blob)
})



}